/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~quill/dist/quill.snow.css';

ng-select.custom-select {
    .ng-arrow-wrapper {
        display: none !important;
    }

    .ng-select-container {
        .ng-value {
            .ng-value-icon {
                float: right;
                transform: rotateY(180deg);
            }
        }
    }
}

ng-select.user-push-notif {
    .ng-select-container {
        .ng-value {
            .ng-value-icon {
                float: right;
                transform: rotateY(180deg);
            }
        }
    }
}

@import "~@ng-select/ng-select/themes/default.theme.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
