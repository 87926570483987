
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-material-sidebar-primary: mat.define-palette(mat.$indigo-palette);
$angular-material-sidebar-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-material-sidebar-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-material-sidebar-theme: mat.define-light-theme((
  color: (
    primary: $angular-material-sidebar-primary,
    accent: $angular-material-sidebar-accent,
    warn: $angular-material-sidebar-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-material-sidebar-theme);

// Quill Editor
.ql-editor {
  padding: 0 !important;
  font-family: 'Wix Madefor Text' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
.ql-editor.ql-blank::before {
  padding: 0px !important;
  font-family: 'Wix Madefor Text' !important;
  left: 5px !important;
  color: #BDBDBD !important;
  font-style: normal !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid #DDDDDD !important; 
}
.ql-container.ql-snow {
  border: none !important;
}
